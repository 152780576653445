import React from 'react'
import './Header.scss'

const Header = ({ name, description, contractAddress }) => (
	<>
		<div className="header">
			<h1 className="">ROBORT</h1>
			<h2>{description || <>&nbsp;</>}</h2>
		</div>
		<div className="credits">dedicated to christiaan</div>
	</>
)

export default Header
