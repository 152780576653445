import Web3 from 'web3'
import { setAlert, setGlobalState, getGlobalState, setLoadingMintShow, setLoadingMintMsg, setLoadingMsg, setLoadingShow, setGoerliUp, setSepoliaUp } from '../store'

const { ethereum } = window

// const network = getGlobalState('network')

const _GOERLI = 5
const _SEPOLIA = 11155111

const handleAccountChange = (...args) => {
	console.log('account changes')
	loadWeb3()
}

ethereum?.on('accountsChanged', handleAccountChange)
ethereum?.on('networkChanged', (x) => {
	console.log('network action!', x)
	loadWeb3()
})

const loadWeb3 = async () => {
	try {
		if (!ethereum) {
			console.log('Please install Metamask or Web3 Wallet')
			setLoadingMsg('Please install Metamask or Web3 Wallet')
			return
		} else {
			console.log('window.ethereum is good')
		}

		setLoadingMsg('Loading Web3...')
		setLoadingShow(true)

		console.log(ethereum)

		// accountsChanged
		// connect
		// disconnect
		// error
		// message
		// networkChanged

		window.web3 = new Web3(ethereum)
		try {
			const kek = await ethereum.enable()
			// console.log(kek)
		} catch (err) {
			console.log('could not enable ethereum, wallet is locked?')
			setLoadingMsg('Please unlock your wallet to continue.')
			return
		}
		// console.log(window.web3.currentProvider)
		window.web3 = new Web3(window.web3.currentProvider)

		const web3 = window.web3
		web3.eth.handleRevert = true

		const networkId = await web3.eth.net.getId()

		setGlobalState('network', networkId === _SEPOLIA ? 'sepolia' : networkId === _GOERLI ? 'goerli' : networkId === 1 ? 'mainnet' : 'not found')

		const network = getGlobalState('network')
		if (network === 'goerli') {
			setGoerliUp()
		}
		if (network === 'sepolia') {
			setSepoliaUp()
		}
		console.log({ networkId })

		// If not Sepolia or Goerli, cahnge to sepolia
		if (networkId !== _SEPOLIA && networkId !== _GOERLI) {
			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: web3.utils.toHex(_SEPOLIA) }],
				})
			} catch (err) {
				// This error code indicates that the chain has not been added to MetaMask
				if (err.code === 4902) {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainName: 'Ethereum Testnet',
								chainId: web3.utils.toHex(_SEPOLIA),
								nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
								rpcUrls: ['https://polygon-rpc.com/'],
							},
						],
					})
				}
			}
		}
		// Goerli or Sepolia
		if (networkId === _GOERLI || networkId === _SEPOLIA) {
			try {
				console.log('running goerli or sepolia:', network)

				const accounts = await web3.eth.getAccounts()
				setGlobalState('connectedAccount', accounts[0])
				console.log(accounts[0])

				// const networkId = await web3.eth.net.getId();
				// const networkData = Adulam.networks[networkId];
				const selectedContract = getGlobalState('selectedContract')

				console.log({ selectedContract })

				const contract = new web3.eth.Contract(selectedContract.abi, selectedContract.contractAddress)
				const name = await contract.methods.name().call()
				const symbol = await contract.methods.symbol().call()
				const cooldown = await contract.methods.cooldown().call()
				const multiMintAmount = await contract.methods.multiMintAmount().call()
				const lastMintedTimestamp = await contract.methods.lastMintedTimestamp(accounts[0]).call()
				const lastMultiMintedTimestamp = await contract.methods.lastMultiMintedTimestamp(accounts[0]).call()
				let baseURI
				try {
					baseURI = await contract.methods.baseURI().call()
				} catch (error) {
					baseURI = 'non-standard'
				}
				let totalSupply
				try {
					totalSupply = await contract.methods.totalSupply().call()
				} catch (error) {
					totalSupply = 'non-standard'
				}

				// setContractName(name);
				// setContractSymbol(symbol);
				// setBaseUri(baseURI);
				// setTotalSupply(totalSupply);

				setGlobalState('contractOnChainDetails', {
					name,
					symbol,
					baseURI,
					totalSupply,
					cooldown,
					multiMintAmount,
					lastMintedTimestamp,
					lastMultiMintedTimestamp,
				})
				setGlobalState('contract', contract)
				// setGlobalState("contractAddress", selectedContract.contractAddress);
				setGlobalState('id', selectedContract.id)
				setGlobalState('description', selectedContract.description)
			} catch (error) {
				console.log('Unknown Error', error)
			}
		} else {
			console.log('Please connect to the Sepolia network!')
			setLoadingMsg('Please connect to the Sepolia network to continue.')
		}
	} catch (error) {
		console.log('Something else went wrong!', error)
	}
}

const connectWallet = async () => {
	try {
		if (!ethereum) return alert('Please install Metamask')
		const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
		setGlobalState('connectedAccount', accounts[0])
	} catch (error) {
		setAlert(JSON.stringify(error), 'red')
	}
}

function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

const payForArt = async (payload) => {
	console.log('mint payload', payload)
	try {
		const { buyer } = payload
		// const web3 = window.web3;
		// const cost = web3.utils.toWei("0.01", "ether");

		const contract = getGlobalState('contract')
		console.log(contract)
		setLoadingMintMsg(
			<>
				<strong>Minting in progress...</strong> check your wallet.
			</>,
		)

		// call the mint function on the contra ct
		// console.log("Starting Mint: ");
		// const gasEstimate = await contract.methods.safeMint(buyer).estimateGas({
		// 	from: buyer,
		// })

		// console.log('gasEstimate', gasEstimate)

		const mintingResult = await contract.methods
			.safeMultiMint(buyer)
			.send({
				from: buyer,
				// gasPrice: web3.utils.toWei("15", "gwei"), // set gas price to 5 Gwei
				// gas: gasEstimate, // set gas limit to 500,000
				// value: , // set value to 0 Ether
				// nonce: 469,
			})
			.then((receiptResponse) => {
				console.log('Transaction receipt:', receiptResponse)
				// receipt = receiptResponse
				setLoadingMintMsg(<strong>Minting successful!</strong>)
				return receiptResponse
			})
			.catch((err) => {
				console.log('Error in mint:', err)
				setLoadingMintMsg('')
				setLoadingMintShow(false)
				betterMessage(err)
				return false
			})

		console.log('----', { mintingResult })
		return mintingResult

		// console.log('done waiting')
		// setLoadingMintShow(false)
		// setLoadingMintMsg(
		// 	<>
		// 		Thank you for minting! <a href="#">{receipt?.blockHash}</a>
		// 	</>,
		// )
		// await contract.methods
		//   //   .payToMint(title, description)
		//   .safeMint(buyer, tokenId)
		//   .send({ from: buyer, value: gasEstimate });
		// if()
		// setLoadingMsg("Minting successful!");

		// return false
	} catch (err) {
		console.error('ERROR over payForArt', err)
		betterMessage(err)
	}
}

const mint = async () => {
	const connectedAccount = getGlobalState('connectedAccount')
	const contract = getGlobalState('contract') // Interact with this object

	setGlobalState('loadingMint', { show: true, msg: 'Initializing transaction...' })

	const totalSupplyByEventFiltering = await contract.getPastEvents('Transfer', {
		filter: {
			from: '0x0000000000000000000000000000000000000000',
		},
		fromBlock: 0,
	})

	console.log('📟 totalSupplyByEventFiltering', totalSupplyByEventFiltering.length)
	// setTotalSupplyAccordingToEventFiltering(totalSupplyByEventFiltering.length)
	// const newTokenToMint = totalSupplyByEventFiltering.length + 1

	payForArt({
		buyer: connectedAccount,
	}).then((result) => {
		console.log({ result })
		if (result) {
			// setGlobalState('loadingMint', { show: false, msg: '' })

			// setLoadingMintShow(false)

			setLoadingMintMsg(
				<p>
					Thank you for minting! <a href={`https://goerli.etherscan.io/tx/${result?.transactionHash}`}>Transaction on etherscan</a>
					<br />
					This page will reload in a few seconds
				</p>,
			)

			setTimeout(() => {
				setLoadingMintShow(false)
				setLoadingMintMsg('')
				loadWeb3()
			}, 10000)

			// setAlert('Minting Successful...', 'green')
		}
	})

	return false
}

const betterMessage = (err) => {
	const endIndex = err.message.search('{')
	console.log(endIndex)
	if (endIndex >= 0) {
		console.log('Error 1:', err.message.substring(0, endIndex))
		setAlert(err.message.substring(0, endIndex), 'text-red-500')
		setGlobalState('loading', { show: false, msg: '' })
	} else {
		setAlert(err.message, 'text-red-500')
		setGlobalState('loading', { show: false, msg: '' })
	}
}

export { loadWeb3, connectWallet, payForArt, mint }

/*
 
  // const claimNFTs = async () => {
  //   let cost = CONFIG.WEI_COST;
  //   let gasLimit = CONFIG.GAS_LIMIT;
  //   let totalCostWei = String(cost * mintAmount);
  //   let totalGasLimit = String(gasLimit * mintAmount);
  //   console.log("Cost: ", totalCostWei);
  //   console.log("Gas limit: ", totalGasLimit);
  //   // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
  //   // setClaimingNft(true);
  //   await contract.methods
  //     .safeMint(buyer, 1)
  //     .send({
  //       gasLimit: 285000,
  //       to: CONFIG.CONTRACT_ADDRESS,
  //       from: blockchain.account,
  //       value: blockchain.web3.utils.toWei((0).toString(), "ether"),
  //     })
  //     .once("error", (err) => {
  //       console.log(err);
  //       setFeedback("Sorry, something went wrong please try again later.");
  //       setClaimingNft(false);
  //     })
  //     .then((receipt) => {
  //       console.log(receipt);
  //       setFeedback(
  //         `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
  //       );
  //       setClaimingNft(false);
  //       dispatch(fetchData(blockchain.account));
  //     });
  // };
  */

/*
// const structuredNfts = (nfts) => {
//   const web3 = window.web3;
//   return nfts
//     .map((nft) => ({
//       id: nft.id,
//       to: nft.to,
//       from: nft.from,
//       cost: web3.utils.fromWei(nft.cost),
//       title: nft.title,
//       description: nft.description,
//       timestamp: nft.timestamp,
//     }))
//     .reverse();
// };
*/
