import React from 'react'
import { format } from 'date-fns'
import { setAlert, setGlobalState, getGlobalState, setLoadingMintShow, setLoadingMintMsg, setLoadingMsg, setLoadingShow } from '../store'

import './Footer.scss'

const network = getGlobalState('network')

const Footer = ({ contractAddress }) => (
	<>
		<div className="footer">
			<div>
				<a href={`https://${network}.etherscan.io/address/${contractAddress}`}>Etherscan</a>
				<a href={`https://testnets.opensea.io/collection/robort`}>Opensea</a>
				{/* <span>{format(new Date(), "'Last built: 'dd-MMM-yyyy")}</span> */}
				<span>04-Sep-2023</span>
			</div>
			<div>
				<a href={`https://robort.foxinni.com`}>https://robort.foxinni.com</a>
				{/* <a href={`https://foxinni.com`}>https://foxinni.com</a> */}
				<a href={`https://x.com/foxinni`}>@foxinni</a>
			</div>
		</div>
	</>
)

export default Footer
