import React, { useState } from 'react'
import { setAlert, setGlobalState, useGlobalState, getGlobalState, setLoadingMsg } from '../store'
import { payForArt, loadWeb3, mint } from './Adulam'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

import robortIcon from '../robort-icon.png'

import './Mintbox.scss'

const Mintbox = ({ contractOnChainDetails }) => {
	const [loading] = useGlobalState('loading')
	const [alert] = useGlobalState('alert')
	const [loadingMint] = useGlobalState('loadingMint')
	// const [totalSupplyAccordingToEventFiltering, setTotalSupplyAccordingToEventFiltering] = useState(1)
	const { name, symbol, baseURI, multiMintAmount, totalSupply, cooldown, lastMintedTimestamp, lastMultiMintedTimestamp } = contractOnChainDetails

	// console.log(lastMultiMintedTimestamp && format(new Date(lastMultiMintedTimestamp), "'Today is a' eeee"))

	const date = new Date(lastMultiMintedTimestamp * 1000)
	const date2 = new Date(lastMintedTimestamp * 1000)

	const timeOutput = Number(lastMultiMintedTimestamp) > 0 && date ? `Last mint was ${format(date, 'h:mmaaa dd/MM/yyyy')}` : 'This wallet has not minted yet'
	// const timeOutput2 = Number(lastMintedTimestamp) > 0 && date ? `Last mint was ${format(date2, 'hh:mmaaa dd/MM/yyyy')}` : 'This wallet has not minted yet'

	// console.log(output)

	return (
		<div className="mintbox">
			<div className="mintable-grid">
				{loading.show ? (
					<div className="loading">{loading.msg}</div>
				) : (
					[...Array(Number(multiMintAmount))].map((x, j) => {
						const i = Number(j) + Number(totalSupply) + 1
						console.log(i)
						return (
							<div key={`robort-${i}`}>
								<div>
									<div className="id">&nbsp;</div>
								</div>
								<div>{baseURI ? <img alt={`Preview of #${i}`} src={`${baseURI ? `${baseURI}${i}.png` : ``}`} /> : <span className=""></span>}</div>
								<div>
									<div className="id">#{i || <>&nbsp;</>}</div>
								</div>
							</div>
						)
					})
				)}
			</div>
			<div className="mintable-action-bar">
				{loading.show ? (
					<>
						{/* <div class="lds-ripple">
							<div></div>
							<div></div>
						</div> */}
						<img className="icon" src={robortIcon} alt="ROBORT Website Logo" />
					</>
				) : (
					<>
						<div>
							{loadingMint.show ? (
								<>
									<div class="lds-ripple">
										<div></div>
										<div></div>
									</div>
								</>
							) : alert.msg ? (
								alert.msg
							) : (
								<button className="" disabled={loadingMint.show} onClick={() => mint()}>
									Mint to your wallet
								</button>
							)}
						</div>
						<div>
							{loadingMint.show ? (
								<>{loadingMint.msg}</>
							) : (
								<p>
									<span>&nbsp;</span>
									Mint some of these fun Robots to your wallet
									<br />
									{/* {multiMintAmount} NFTs available every {(Number(cooldown) / 60 / 60).toFixed(4).replace('.0000', '')} hours */}
									{multiMintAmount} NFTs available every 24 hours
									<br />
									<span>{timeOutput}</span>
								</p>
							)}
							<>&nbsp;</>
							{/* <>{alert.show ? <div className={alert.color || ''}>{alert.msg}</div> : <>All systems good!</>}</> */}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default Mintbox
