import { createGlobalState } from 'react-hooks-global-state'
import MyTokenAbi from '../contracts/mintMachine.abi.json'
import NFTFaucetABI from '../contracts/NFTFaucet.json'
import NFTFaucet2ABI from '../contracts/NFTFaucet2.json'

const contracts = {
	// mytoken: {
	// 	id: 'mytoken',
	// 	contractAddress: '0x658900998e8209521399c6f7652ab057d2c79610', //MyToken MTKX2 / Dali Pizza
	// 	abi: MyTokenAbi,
	// 	description: 'Mint a NFT to your wallet. No minting limit.',
	// },
	// nftfaucet1: {
	// 	id: 'nftfaucet',
	// 	contractAddress: '0xf011cad5e35177c3a90120fe518f75987d7d1bfb', // Retro Computer
	// 	abi: NFTFaucetABI.abi,
	// 	description: 'Mint a NFT to your wallet. Limited to one mint once every 24-hours.',
	// },
	// nftfaucet2: {
	// 	id: 'nftfaucet2',
	// 	contractAddress: '0xC052997F522C6692B7A38DB58C012CDBb3dfBd74', // Candy Pizza
	// 	abi: NFTFaucet2ABI.abi,
	// 	description: 'Mint a NFT to your wallet. No minting limit (is changeable).',
	// },
	default: {
		id: '',
		contractAddress: '', // Robort
		abi: {},
		description: 'NFT Faucet',
	},
	robortGoerli: {
		id: 'robort-goerli',
		contractAddress: '0x30d40F879334a9654D41643F21f623afD9bB3201', // Robort
		abi: NFTFaucet2ABI.abi,
		description: 'NFT Faucet for Goerli',
	},
	robortSepolia: {
		id: 'robort-sepolia',
		contractAddress: '0x576fe471011F1130342342e1bd539Df6408d8Fd6', // Robort
		abi: NFTFaucet2ABI.abi,
		description: 'NFT Faucet for Sepolia',
	},
}

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
	network: '',
	id: contracts.default.id,
	description: contracts.default.description,
	alert: { show: false, msg: '', color: '' },
	loading: { show: true, msg: 'Loading...' },
	loadingMint: { show: true, msg: '' },

	selectedContract: contracts.default,
	contract: null,
	contractOnChainDetails: {
		name: 'ROBORT',
		symbol: 'ROBORT',
		baseUri: '',
		totalSupply: '',
		baseURI: '',
		multiMintAmount: '',
		cooldown: '',
		lastMintedTimestamp: '',
		lastMultiMintedTimestamp: '',
	},
	maxSupply: 100,
	connectedAccount: '',
})

const setGoerliUp = () => {
	setGlobalState('id', contracts.robortGoerli.id)
	setGlobalState('description', contracts.robortGoerli.description)
	setGlobalState('selectedContract', contracts.robortGoerli)
}
const setSepoliaUp = () => {
	setGlobalState('id', contracts.robortSepolia.id)
	setGlobalState('description', contracts.robortSepolia.description)
	setGlobalState('selectedContract', contracts.robortSepolia)
}

const setAlert = (msg, color = 'green') => {
	setGlobalState('alert', { show: true, msg, color })
	console.log('kek', getGlobalState('alert'))
	setTimeout(() => {
		setGlobalState('alert', { show: false, msg: '', color })
		setGlobalState('loading', false)
	}, 2000)
}

const setLoadingShow = (show) => {
	const loading = getGlobalState('loading')
	setGlobalState('loading', { ...loading, show })
}
const setLoadingMsg = (msg) => {
	const loading = getGlobalState('loading')
	setGlobalState('loading', { ...loading, msg })
}

const setLoadingMintShow = (show) => {
	const loadingMint = getGlobalState('loadingMint')
	setGlobalState('loadingMint', { ...loadingMint, show })
}
const setLoadingMintMsg = (msg) => {
	const loadingMint = getGlobalState('loadingMint')
	setGlobalState('loadingMint', { ...loadingMint, msg })
}

export { useGlobalState, setGlobalState, getGlobalState, setAlert, setLoadingShow, setLoadingMsg, setLoadingMintShow, setLoadingMintMsg, setGoerliUp, setSepoliaUp }
