import './App.scss'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { setAlert, setGlobalState, useGlobalState, getGlobalState, setLoadingMsg } from '../store'
import { payForArt, loadWeb3, mint } from './Adulam'
// import { mint } from './functions/mint'
import Loader from './Loader'
import Header from './Header'
import Mintbox from './Mintbox'
import Debugbox from './Debugbox'
import Footer from './Footer.js'

const { ethereum } = window

function App() {
	// Global State
	const [network] = useGlobalState('network')
	const [loading] = useGlobalState('loading')
	const [alert] = useGlobalState('alert')
	const [contract] = useGlobalState('contract') // Interact with this object
	const [connectedAccount] = useGlobalState('connectedAccount')
	const [description] = useGlobalState('description')
	const [contractOnChainDetails] = useGlobalState('contractOnChainDetails')

	console.log(contractOnChainDetails)

	// Desconstructing
	const { contractAddress } = getGlobalState('selectedContract')

	// Local state

	useEffect(() => {
		loadWeb3()
	}, [])

	// useEffect(() => {
	// 	console.log('changes in ethereum')
	// }, [ethereum])

	useEffect(() => {
		async function autoRun() {
			const network = getGlobalState('network')

			console.log('Auto Run: ', network)
			// Total Supply
			const totalSupplyByEventFiltering = await contract.getPastEvents('Transfer', {
				filter: {
					from: '0x0000000000000000000000000000000000000000',
				},
				fromBlock: 0,
			})

			console.log('📟 totalSupplyByEventFiltering', totalSupplyByEventFiltering.length)

			// setTotalSupplyAccordingToEventFiltering(totalSupplyByEventFiltering.length)

			// const tokenId = totalSupplyByEventFiltering.length + 1;
			// console.log("🥇 Token to mint: ", tokenId);
			// setTokenToMint(tokenId);
			let web3
			web3 = new Web3(`https://eth-${network}.g.alchemy.com/v2/XacnrNRf8EfQIzw9GKn131eQOPNmEawJ`)

			const nonce = await web3.eth.getTransactionCount(connectedAccount, 'latest')
			console.log('#️⃣ Nonce: ', nonce)

			setGlobalState('loading', { show: false })
			setGlobalState('loadingMint', { show: false })

			// console.log("📝 Contract Details:", { symbol, name });

			// ! Gas Estimate
			// try {
			//   const gasEstimate = await contract.methods
			//     .safeMint(connectedAccount)
			//     .estimateGas({
			//       from: connectedAccount,
			//     });
			//   console.log("🧪 GAS Estimate: ", gasEstimate);
			// } catch (error) {
			//   console.log(
			//     error?.message === "execution reverted: Can only mint once per day"
			//   );
			//   console.log(error?.message);
			// }

			// contract
			//   .getPastEvents("Transfer", {
			//     filter: {
			//       from: "0x0000000000000000000000000000000000000000",
			//     },
			//     fromBlock: 0,
			//   })
			//   .then((events) => {
			//     console.log("⏏️ Total Supply: ", events.length);
			//     console.log(events);
			//     for (let event of events) {
			//       console.log(event.returnValues.tokenId);
			//     }
			//   });

			// const kek = await contract.methods.tokenURI(6).call();

			// console.log(kek);
			// .then((receipt) => {
			//   console.log("Transaction receipt:", receipt);
			// })
			// .catch((error) => {
			//   console.error("Error:", error);
			// });

			// call the mint function on the contra ct
			// console.log("Starting Mint: ");
			// await contract.methods
			//   .safeMint(toAddress)
			//   .send({
			//     from: sender,
			//     //   gasPrice: web3.utils.toWei("5", "gwei"), // set gas price to 5 Gwei
			//     //   gas: gasEstimate, // set gas limit to 500,000
			//     //   value: 0, // set value to 0 Ether
			//   })
			//   .then((receipt) => {
			//     console.log("Transaction receipt:", receipt);
			//   })
			//   .catch((error) => {
			//     console.error("Error:", error);
			//   });

			// console.log("Minted: ", minted);
		}

		contractAddress && contract && connectedAccount && autoRun()
	}, [contractAddress, contract, connectedAccount])

	console.log({
		contractOnChainDetails,
	})

	const { name, symbol, baseURI, multiMintAmount, totalSupply, cooldown } = contractOnChainDetails

	return (
		<div className="app-wrapper">
			<div className="">
				<Header name={name} description={description} contractAddress={contractAddress} />

				<Mintbox contractOnChainDetails={contractOnChainDetails} />

				<div className="connected-status">{loading.show ? <>&nbsp;</> : connectedAccount && <>Connected wallet: {connectedAccount}</>}</div>

				<Footer contractAddress={contractAddress} />

				{/* <Debugbox contractOnChainDetails={contractOnChainDetails} /> */}
			</div>
		</div>
	)
}

export default App
